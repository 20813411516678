import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Button } from '@/components/ui/Button.tsx';
import Dialog from '@/components/ui/Dialog.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { PreparedMigrationData } from '@/features/administrator-panel/omnibus/migration/PreparedMigrationData.tsx';
import toast from 'react-hot-toast';

type StartMigrationDialogProps = {
  omnibusId: string;
  open: boolean;
  onClose: () => void;
};

export const StartMigrationDialog = ({
  omnibusId,
  open,
  onClose,
}: StartMigrationDialogProps) => {
  const navigate = useNavigate();

  const {
    data: preparedMigrationData,
    isPending: isPreparing,
    mutate: prepare,
  } = useMutation({
    mutationFn: async () =>
      (
        await crownApi.AdminOmnibusMigration_prepareMigration(null, {
          omnibusId,
        })
      ).data,
  });

  const { isPending: isStartingMigration, mutate: startMigration } =
    useMutation({
      mutationFn: async () =>
        (
          await crownApi.AdminOmnibusMigration_startMigration(null, {
            omnibusId,
          })
        ).data,
      onSuccess: async (migration) => {
        onClose();
        await navigate({
          to: '/admin/configuration/omnibus/migration/$migrationId',
          params: {
            migrationId: migration.migrationId,
          },
        });
        toast.success(
          'Migration started and will run in the background (this window does not need to remain open)',
        );
      },
    });

  return (
    <Dialog open={open} onClose={onClose} size={'small'}>
      <Typography variant={'h2'} className={'text-center'}>
        Migrate to Proxy Account
      </Typography>

      {preparedMigrationData && (
        <PreparedMigrationData data={preparedMigrationData} />
      )}

      <div className={'mt-8 flex justify-center items-center flex-col gap-y-2'}>
        <Button
          onClick={() =>
            preparedMigrationData?.result?.result === 'ready'
              ? startMigration()
              : prepare()
          }
          loading={isPreparing || isStartingMigration}
          size={'lg'}
        >
          {preparedMigrationData?.result?.result === 'ready'
            ? 'Start Migration'
            : 'Continue...'}
        </Button>
      </div>
    </Dialog>
  );
};
