import { CloudX } from '@phosphor-icons/react';
import { ErrorComponentProps } from '@tanstack/react-router';
import { FC, useEffect, useState } from 'react';

import { extractErrorFromResponse } from '@/common/util/extractErrorFromResponse.ts';
import { Typography } from '@/components/ui/Typography.tsx';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/Button';

export const ErrorStateText = ({ error }: { error: string; }) => {
  return (
    <h3 className={'text-error/70 font-semibold text-lg text-center max-w-xl'}>
      {error}
    </h3>
  );
};

type ErrorStateResponseProps = {
  error: Response;
};

export const ErrorStateResponse = ({ error }: ErrorStateResponseProps) => {
  const [errorString, setErrorString] = useState('');
  useEffect(() => {
    (async () => {
      const data = await extractErrorFromResponse(error);
      setErrorString(
        data || 'An unexpected error occurred when loading this page',
      );
    })();
  }, []);

  return <ErrorStateText error={errorString || ''} />;
};

type ErrorStateProps = {
  error: string | ErrorComponentProps['error'];
  onRetry?: () => void;
  className?: string;
};

export const ErrorState: FC<ErrorStateProps> = ({ error, onRetry, className }) => {
  return (
    <div
      className={cn(
        'w-full flex justify-center items-center flex-col py-60 gap-y-2',
        className,
      )}
    >
      <div className={'flex justify-center gap-x-4 items-center'}>
        <CloudX className={'w-14 h-14 text-error'} />

        <Typography variant={'h3'} className={'text-error'}>
          Something went wrong
        </Typography>
      </div>
      {typeof error === 'string' && <ErrorStateText error={error} />}
      {error instanceof Response && <ErrorStateResponse error={error} />}
      {!!onRetry && <Button variant={'destructive'} onClick={onRetry} className='mt-2' size={'lg'}>Try Again</Button>}
    </div>
  );
};
