import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid/index';
import { HTMLAttributes } from 'react';

import { IconType } from '@/components/ui/Input.tsx';
import { cn } from '@/lib/utils.ts';

export enum CalloutBoxType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

type Variant = {
  icon: IconType;
  classes: string;
  textClasses: string;
  iconClasses: string;
};

const calloutBoxVariants: Record<CalloutBoxType, Variant> = {
  [CalloutBoxType.INFO]: {
    icon: InformationCircleIcon,
    classes: 'border-blue-400 bg-blue-50',
    textClasses: 'text-blue-700',
    iconClasses: 'text-blue-400',
  },
  [CalloutBoxType.WARNING]: {
    icon: ExclamationTriangleIcon,
    classes: 'border-yellow-400 bg-yellow-50',
    textClasses: 'text-yellow-700',
    iconClasses: 'text-yellow-400',
  },
  [CalloutBoxType.ERROR]: {
    icon: XCircleIcon,
    classes: 'border-red-400 bg-red-50',
    textClasses: 'text-red-700',
    iconClasses: 'text-red-400',
  },
};

type CalloutBoxProps = {
  variant: CalloutBoxType;
} & HTMLAttributes<HTMLDivElement>;

export const CalloutBox = ({ variant, ...props }: CalloutBoxProps) => {
  const Icon = calloutBoxVariants[variant].icon;
  return (
    <div
      {...props}
      className={cn(
        'border-l-4 p-4',
        calloutBoxVariants[variant].classes,
        props.className,
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            className={cn(
              'h-5 w-5 text-yellow-400',
              calloutBoxVariants[variant].iconClasses,
            )}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <div className={calloutBoxVariants[variant].textClasses}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
