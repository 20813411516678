import { createFileRoute } from '@tanstack/react-router';

import { listProvidersInfiniteQuery } from '@/features/administrator-panel/provider/api/providersApi.ts';
import { PROVIDERS_PER_PAGE } from '@/features/administrator-panel/provider/ProviderManagement.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';

export const Route = createFileRoute('/admin/providers/')({
  validateSearch: (
    search: Record<string, unknown>,
  ): { search: string } | void => {
    if (!('search' in search)) {
      return;
    }
    return {
      search: search.search as string,
    };
  },
  loader: () =>
    crownQueryClient.ensureInfiniteQueryData(
      listProvidersInfiniteQuery({ perPage: PROVIDERS_PER_PAGE }),
    ),
});
