import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getWireFeeQuery } from '@/features/portal/deposit-accounts/account/api/userDepositAccountTransfersAPI.ts';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId/home/wires',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      getWireFeeQuery(params.depositAccountId),
    ),
  meta: () => [{ title: 'Wires' }],
});
