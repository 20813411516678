import { z } from 'zod';

import { createFileRoute } from '@tanstack/react-router'
import { listApplicationsInfiniteQuery } from '@/features/provider-panel/applications/api/applicationsListApi.ts';
import { APPLICATIONS_PER_PAGE } from '@/components/ui/applications/constants.ts';
import { objectKeys } from '@/lib/objectKeys.ts';
import type { Components } from '@/openapi';
import { applicationStatusFilters, applicationTypeFilters } from '@/common/const/applicationFilterOptions.ts';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';

export const Route = createFileRoute('/provider/$providerId/applications/')({
  validateSearch: (search) =>
    z
      .object({
        search: z.string().optional(),
        statusFilter: z
          .array(
            z.enum<
              Components.Schemas.ApplicationStatus,
              [Components.Schemas.ApplicationStatus, ...Components.Schemas.ApplicationStatus[]]
            >(objectKeys(applicationStatusFilters)),
          )
          .optional()
          .catch(() => []),
        type: z
          .array(
            z.enum<
              Components.Schemas.ApplicationType,
              [Components.Schemas.ApplicationType, ...Components.Schemas.ApplicationType[]]
            >(objectKeys(applicationTypeFilters)),
          )
          .optional()
          .catch(() => []),
      })
      .default({})
      .parse(search),
  loader: ({ params }) =>
    crownQueryClient.ensureInfiniteQueryData(
      listApplicationsInfiniteQuery({
        providerId: params.providerId,
        limit: APPLICATIONS_PER_PAGE,
        filters: { search: '', status: ['PROVIDER_FILLING'] },
      }),
    ),
})
