import { ChevronRightIcon } from '@heroicons/react/24/solid/index';
import { Anchor } from '@phosphor-icons/react';
import { Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';

import { formatCurrency } from '@/common/util/formatCurrency.tsx';
import { Button } from '@/components/ui/Button.tsx';
import { DashboardContentSurface } from '@/components/ui/layout/dashboard-layout/DashboardContentSurface.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { StartMigrationDialog } from '@/features/administrator-panel/omnibus/migration/StartMigrationDialog.tsx';
import { Components } from '@/openapi';

dayjs.extend(relativeTime);

type OmnibusAccountGridItemProps = {
  omnibus: Components.Schemas.OmnibusAccountListItemDto;
};

export const OmnibusAccountGridItem = ({
  omnibus,
}: OmnibusAccountGridItemProps) => {
  const [migrateDialogOpen, setMigrateDialogOpen] = useState(false);

  return (
    <DashboardContentSurface
      className={'relative'}
      containerClassName={'col-span-1'}
    >
      <Typography variant={'muted'} className={'text-sm absolute top-1 left-2'}>
        {omnibus.lastRefreshed
          ? 'Refreshed ' + dayjs(omnibus.lastRefreshed, { utc: true }).fromNow()
          : ''}
      </Typography>

      <Typography variant={'h4'} className={'text-center mt-2 text-primary'}>
        {omnibus.isDefault && <Anchor className={'w-6 h-6 mr-2 inline'} />}
        {omnibus.name}
        {omnibus.isDefault && ' (default)'}
      </Typography>

      <Typography>
        <span className={'text-muted-foreground'}>
          Total Accounts Balance:{' '}
        </span>
        {formatCurrency(omnibus.totalAccountsBalance)}
      </Typography>

      <Typography>
        <span className={'text-muted-foreground'}>Virtual Accounts #: </span>
        {omnibus.virtualAccountsCount}
      </Typography>

      <Typography>
        <span className={'text-muted-foreground'}>Open Applications #: </span>
        {omnibus.openApplicationsCount}
      </Typography>

      <div className={'flex justify-center mt-4 gap-x-2'}>
        {!omnibus.migratedToProxy && (
          <Button
            variant={'outline'}
            onClick={() => setMigrateDialogOpen(true)}
          >
            Migrate to Proxy...
          </Button>
        )}
        <Link
          to={`/admin/configuration/omnibus/$omnibusAccountId`}
          params={{ omnibusAccountId: omnibus.id }}
        >
          <Button variant={'outline'}>
            View Account
            <ChevronRightIcon
              className={'ml-2 w-4 h-4 text-muted-foreground'}
            />
          </Button>
        </Link>
      </div>
      <StartMigrationDialog
        omnibusId={omnibus.id}
        onClose={() => setMigrateDialogOpen(false)}
        open={migrateDialogOpen}
      />
    </DashboardContentSurface>
  );
};
