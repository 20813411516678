import { ReactNode } from 'react';

import { formatCurrency } from '@/common/util/formatCurrency.tsx';
import { CalloutBox, CalloutBoxType } from '@/components/ui/CalloutBox.tsx';
import ReviewItem from '@/components/ui/review/ReviewItem.tsx';
import ReviewSection from '@/components/ui/review/ReviewSection.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { Components } from '@/openapi';

type PreparedMigrationDataProps = {
  data: Components.Schemas.AdminOmnibusPrepareMigrationResponseDto;
};

export const PreparedMigrationData = ({ data }: PreparedMigrationDataProps) => {
  switch (data.result.result) {
    case 'error':
      return <PreparedMigrationError error={data.result} />;
    case 'insufficientSweepBalance':
      return <PreparedMigrationInsufficientFunds error={data.result} />;
    case 'ready':
      return <MigrationReady data={data.result} />;
  }
};

const PreparedMigrationError = ({
  error,
}: {
  error: Components.Schemas.MigrationPreparationErrorDto;
}) => {
  return (
    <Typography variant={'h4'} className={'text-error'}>
      {error.message}
    </Typography>
  );
};

const PreparedMigrationInsufficientFunds = ({
  error,
}: {
  error: Components.Schemas.MigrationInsufficientSweepBalanceErrorDto;
}) => {
  return (
    <>
      <Typography variant={'h4'} className={'text-error mb-4'}>
        {error.message}
      </Typography>
      <OmnibusData omnibus={error.omnibus} />
    </>
  );
};

const MigrationReady = ({
  data,
}: {
  data: Components.Schemas.MigrationReadyDto;
}) => {
  return (
    <>
      <CalloutBox variant={CalloutBoxType.WARNING}>
        <Typography>
          This action cannot be reversed. After the migration starts, the
          omnibus will be disabled, and the new proxy configuration will be
          ready to use upon completion.
        </Typography>
      </CalloutBox>
      <OmnibusData omnibus={data.omnibus}>
        <ReviewItem title={'JH Customer Name'} value={data.customerName} />
      </OmnibusData>
    </>
  );
};

const OmnibusData = ({
  omnibus,
  children,
}: {
  omnibus: Components.Schemas.PreparedMigrationOmnibusDto;
  children?: ReactNode;
}) => {
  return (
    <ReviewSection>
      <ReviewItem title={'Omnibus'} value={omnibus.name} />
      <ReviewItem
        title={'# Accounts to Migrate'}
        value={omnibus.totalAccounts}
      />
      <ReviewItem
        title={'Total Accounts Value'}
        value={formatCurrency(omnibus.totalBalance)}
      />
      {children}
    </ReviewSection>
  );
};
