import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/portal/deposit-account/$depositAccountId/')({
  beforeLoad: () => {
    throw redirect({
      to: '/portal/deposit-account/$depositAccountId/home',
      from: '/portal/deposit-account/$depositAccountId/',
      replace: true,
    });
  },
})
