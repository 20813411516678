import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const listProxiesQuery = ({
  limit,
}: Omit<Components.Schemas.ListProxyConfigurationsRequestDto, 'page'>) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.AdminProxyConfiguration_listProxyConfigurations({
          page: pageParam,
          limit: limit,
        })
      ).data;
    },
    queryKey: ['admin', 'proxy', 'list', { limit }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });

export const getProxyQuery = (proxyId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.SingleAdminProxyConfiguration_getProxy({ proxyId })).data,
    queryKey: ['admin', 'proxy', 'get', proxyId],
  });

export const listCoreDepositAccounts = (proxyId: string) =>
  queryOptions({
    queryFn: async () =>
      (
        await crownApi.SingleAdminProxyConfiguration_listCoreDepositAccounts({
          proxyId: proxyId,
          page: 1,
          limit: 50,
        })
      ).data,
    queryKey: [
      'admin',
      'proxy',
      'get',
      proxyId,
      'core-deposit-accounts',
      'list',
    ],
  });
