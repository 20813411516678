import { ChevronRight } from 'lucide-react';

import { QueriedList } from '@/components/list/QueriedList.tsx';
import { QueriedListItem } from '@/components/list/QueriedListItem.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { listProxiesQuery } from '@/features/administrator-panel/proxy/api/proxiesApi.ts';
import { Components } from '@/openapi';

export const PROXIES_PER_PAGE = 10;

export const ProxyConfigurationsList = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <QueriedList
      query={listProxiesQuery({ limit: PROXIES_PER_PAGE })}
      className={className}
    >
      {({ item }) => <ProxyConfigurationListItem item={item} key={item.id} />}
    </QueriedList>
  );
};

export const ProxyConfigurationListItem = ({
  item,
}: {
  item: Components.Schemas.ProxyConfigurationDto;
}) => {
  return (
    <QueriedListItem
      key={item.id}
      className={'p-4 grid grid-cols-3'}
      link={{
        to: '/admin/configuration/proxy/$proxyId',
        params: { proxyId: item.id },
      }}
    >
      <div className={'flex flex-col items-start justify-center'}>
        <Typography variant={'h4'} className={'text-primary text-lg -mb-1'}>
          {item.name}
        </Typography>
      </div>
      <div className={'flex justify-between items-center'}>
        <Typography
          variant={'muted'}
          className={'text-lg'}
        >{`${item.depositAccountCount.toLocaleString()} Accounts`}</Typography>
      </div>
      <div className={'flex items-center justify-end'}>
        <ChevronRight className={'w-8 h-8 text-muted-foreground'} />
      </div>
    </QueriedListItem>
  );
};
