import {
  getSessionToken,
  isSessionTokenExpired,
  refresh,
} from '@descope/react-sdk';
import * as Sentry from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { Document, OpenAPIClientAxios } from 'openapi-client-axios';
import { toast } from 'react-hot-toast';
import definition from '../../../../backend/swagger.json';

import { Client } from '@/openapi';

export const crownApi = new OpenAPIClientAxios({
  definition: definition as unknown as Document,
}).initSync<Client>();

const publicPaths = ['/api/meta/theming'];

crownApi.api.getClient().then((client) => {
  client.interceptors.request.use(async (config) => {
    if (publicPaths.some((path) => config.url?.endsWith(path))) return config;

    if (isSessionTokenExpired()) {
      await refresh();
    }

    config.headers.set('Authorization', `Bearer ${getSessionToken()}`);
    return config;
  });
});

export const crownQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // Warning: dismounting and remounting components may bypass no retries
      // setting when response is an error (assumes empty cache and "first" load)
      retry: 3,
      // Exponential backoff
      retryDelay: (attemptIndex) => Math.min(500 * 2 ** attemptIndex, 30000),
    },
    mutations: {
      onError: async (error) => {
        Sentry.captureException(error);
        console.error(error);
        if (
          isAxiosError(error) &&
          error.response &&
          'message' in error.response.data
        ) {
          toast.error(
            error.response.data.message || 'An unexpected error occurred.',
          );
        } else {
          console.error('error', error);
          toast.error('An unexpected error occurred.');
        }
      },
    },
  },
});
