import { ReactNode } from 'react';

import { IconType } from '@/components/ui/Input.tsx';

type ReviewItemProps = {
  title: ReactNode;
  value: ReactNode;
  icon?: IconType;
};

const ReviewItem = ({ icon: Icon, title, value }: ReviewItemProps) => {
  const iconClasses = 'h-5 w-5 mx-2 text-gray-500 hidden md:inline-block flex-none';
  return (
    <tr className="text-lg">
      <td className="py-1.5">
        <span className="flex items-center text-gray-500">
          {Icon ? (
            <Icon className={iconClasses} />
          ) : (
            <span className={iconClasses} />
          )}
          {title}
        </span>
      </td>
      <td>{value}</td>
    </tr>
  );
};

export default ReviewItem;
