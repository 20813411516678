import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

interface UserDepositAccountTransfersQueryParams {
  accountId: string;
  page: number;
  limit: number;
  filters?: {
    type?: Components.Schemas.TransferType;
  };
}

export const listUserDepositAccountTransfersQuery = ({ accountId, page, limit, filters }: UserDepositAccountTransfersQueryParams) => queryOptions({
  queryFn: async () => (await crownApi.UserDepositAccountTransfers_getTransfers({ accountId, limit: limit || 10, page, filters })).data,
  queryKey: ['user', 'depositAccount', accountId, 'transfer', 'list', { page, limit, filters }]
});

export const getWireFeeQuery = (accountId: string) =>
  queryOptions({
    queryFn: async () =>
      (
        await crownApi.UserDepositAccountTransfers_getWireFee({ accountId })
      ).data!,
    queryKey: ['user', 'depositAccount', accountId, 'wireFee'] as const,
  });

export const getWireAgreementUrlQuery = (accountId: string) =>
  queryOptions({
    queryFn: async () =>
      (
        await crownApi.UserDepositAccountTransfers_getWireAgreementPdfUrl({ accountId })
      ).data!,
    queryKey: ['user', 'depositAccount', accountId, 'wireAgreementUrl'] as const,
  });

export const createWireTransferMutation = {
  mutationFn: async ({
    accountId,
    ...wireInput
  }: Components.Schemas.CreateWireTransferParamsDto & {
    accountId: string;
  }) =>
    (await crownApi.UserDepositAccountTransfers_createWireTransfer(accountId, wireInput))
      .data,
};

export const createACHTransferMutation = {
  mutationFn: async ({
    accountId,
    ...achInput
  }: Components.Schemas.CreateACHTransferParamsDto & {
    accountId: string;
  }) =>
    (await crownApi.UserDepositAccountTransfers_createACHTransfer(accountId, achInput))
      .data,
};
