import { infiniteQueryOptions, keepPreviousData, queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const listMigrationsInfiniteQuery = ({
  limit,
}: Omit<Components.Schemas.AdminListOmnibusMigrationsParamsDto, 'page'>) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.AdminOmnibusMigration_listOmnibusMigrations({
          limit,
          page: pageParam,
        })
      ).data;
    },
    queryKey: ['admin', 'omnibus', 'migration', 'list', { limit }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
    refetchInterval: 3000,
  });

export const getMigrationQuery = (migrationId: string) => queryOptions({
  queryFn: async () => (await crownApi.AdminOmnibusMigration_getMigration(migrationId)).data,
  queryKey: ['admin', 'omnibus', 'migration', 'get', {migrationId}],
  refetchInterval: 3000
})
