import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';

export const getCustomerQuery = (userId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.AdminCustomerManagement_getCustomer({ userId })).data,
    queryKey: ['customers', 'customer', { userId }],
  });
