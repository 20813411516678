import { createFileRoute } from '@tanstack/react-router'
import { listProviderTeamUserQuery } from '@/features/provider-panel/team/api/providerTeamManagementApi.ts';
import { TEAM_USERS_PER_PAGE } from '@/features/provider-panel/team/constants.ts';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';

export const Route = createFileRoute('/provider/$providerId/team/')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      search: (search.search as string) || undefined,
    };
  },
  loader: ({ params }) => crownQueryClient.ensureInfiniteQueryData(
    listProviderTeamUserQuery({ providerId: params.providerId, perPage: TEAM_USERS_PER_PAGE }),
  ),
})
