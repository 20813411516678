import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getMigrationQuery } from '@/features/administrator-panel/omnibus/api/migrationsApi.ts';

export const Route = createFileRoute(
  '/admin/configuration/omnibus/migration/$migrationId',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getMigrationQuery(params.migrationId)),
  meta: ({ loaderData }) => [{ title: `${loaderData.omnibusName} Migration` }],
});
