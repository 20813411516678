import { Pencil } from '@phosphor-icons/react';
import { ReactNode } from 'react';

import { Button } from '@/components/ui/Button.tsx';
import HorizontalRule from '@/components/ui/HorizontalRule.tsx';
import { cn } from '@/lib/utils.ts';

type ReviewSectionProps = {
  title?: ReactNode;
  description?: string;
  children: ReactNode;
  onEdit?: () => void;
  className?: string;
};

const ReviewSection = ({
  title,
  children,
  onEdit,
  description,
  className,
}: ReviewSectionProps) => (
  <div className={cn('bg-white rounded-lg mb-4', className)}>
    <div className="w-full flex justify-between items-center mb-1">
      {title && <p className="w-full text-lg font-semibold text-primary">{title}</p>}
      {onEdit && (
        <Button size="sm" variant="outline" onClick={onEdit} type={'button'}>
          <Pencil className={'h-5 w-5 mr-2'} />
          Edit
        </Button>
      )}
    </div>
    {title && <HorizontalRule className={'mb-2'} />}
    {Boolean(description) && (
      <p className="text-base italic text-gray-600 mb-2">{description}</p>
    )}
    <table className="w-full">
      <colgroup>
        <col style={{ width: '50%' }} />
        <col style={{ width: '50%' }} />
      </colgroup>
      <tbody className="divide-y">{children}</tbody>
    </table>
  </div>
);

export default ReviewSection;
